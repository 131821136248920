import TestColumnModule from "@/components/admin/creationPaperTemplate/TestColumnModule.vue";
import SinglechoiceModule from "@/components/admin/creationPaperTemplate/SinglechoiceModule.vue";
import MultiplechoiceModule from "@/components/admin/creationPaperTemplate/MultiplechoiceModule.vue";
import JudgeechoiceModule from "@/components/admin/creationPaperTemplate/JudgeechoiceModule.vue";
import FillblanksModule from "@/components/admin/creationPaperTemplate/FillblanksModule.vue";
import QuestionsAnswersModule from "@/components/admin/creationPaperTemplate/QuestionsAnswersModule.vue";
import SetPaperStepTwoSingle from "@/components/admin/creationPaperTemplate/SetPaperStepTwoSingle.vue";
import SetPaperStepTwoMultiple from "@/components/admin/creationPaperTemplate/SetPaperStepTwoMultiple.vue";
import SetPaperStepTwoJudge from "@/components/admin/creationPaperTemplate/SetPaperStepTwoJudge.vue";
import SetPaperStepTwoFillBlank from "@/components/admin/creationPaperTemplate/SetPaperStepTwoFillBlank.vue";
import SetPaperStepTwoAskAnswer from "@/components/admin/creationPaperTemplate/SetPaperStepTwoAskAnswer.vue";
import PreviewSinglechoiceModule from "@/components/admin/creationPaperTemplate/PreviewSinglechoiceModule.vue";
import PreviewMultiplechoiceModule from "@/components/admin/creationPaperTemplate/PreviewMultiplechoiceModule.vue";
import PreviewJudgeechoiceModule from "@/components/admin/creationPaperTemplate/PreviewJudgeechoiceModule.vue";
import PreviewFillblanksModule from "@/components/admin/creationPaperTemplate/PreviewFillblanksModule.vue";
import PreviewQuestionsAnswersModule from "@/components/admin/creationPaperTemplate/PreviewQuestionsAnswersModule.vue";
import _ from "underscore";
import { theoryTestPaperFirstPage, theoryTestPaperEdit, theoryTestPaperSecondPage } from "@/utils/apis";

export default {
    name: "TheoryQuestionsAdd",
    components: {
        TestColumnModule,
        SinglechoiceModule,
        MultiplechoiceModule,
        JudgeechoiceModule,
        FillblanksModule,
        QuestionsAnswersModule,
        SetPaperStepTwoSingle,
        SetPaperStepTwoMultiple,
        SetPaperStepTwoJudge,
        SetPaperStepTwoFillBlank,
        SetPaperStepTwoAskAnswer,
        PreviewSinglechoiceModule,
        PreviewMultiplechoiceModule,
        PreviewJudgeechoiceModule,
        PreviewFillblanksModule,
        PreviewQuestionsAnswersModule,
    },
    data() {
        return {
            step: 1,
            theQuestionTypeArr: [],
            theQuestionType: 0, //默认选择全部
            theQuestionDifficulty: [],
            theDiffIndex: 0,
            filtrationValue: "", // 试题分类绑定值
            classes: [], //所有的试题分类
            classProps: {
                checkStrictly: true,
                value: "category_id",
                label: "category_name",
            },
            sortType: 1, //最新上传
            topicContent: "", //输入搜索试题
            questionTotal: 0, // 共计多少道题
            isShow: false, // 显示答案
            allIn: false, //全部添加 & 全部移除
            examPaper: {
                //花篮查看已选题
                classId: 0,
                name: "",
                content: {
                    single: [],
                    multiple: [],
                    judge: [],
                    fillBlank: [],
                    askAnswer: [],
                },
                scoreTotal: 0,
                paper_number: 0,
            },
            questionList: [], //已加入的试题id
            questionIds: [], // 所有可选试题
            paperName: "", //试卷名称
            paperLevel: "", //试卷等级
            levelList: [
                // {
                //     id: 1,
                //     name: "四级电子商务员",
                // },
                // {
                //     id: 2,
                //     name: "三级助理电子商务师",
                // },
                // {
                //     id: 3,
                //     name: "电子商务师二级",
                // }
            ],
            paperCategoryId: "", //试卷分类
            singleSetShow: true, // 试卷信息单选题显示隐藏
            multipleSetShow: true,
            judgeSetShow: true,
            fillBlankSetShow: true,
            askAnswerSetShow: true,
            paperId: null,
            singleItem:[],
            multipleItem:[],
            judgeItem:[],
            fillItem:[],
            questionItem:[],
            lotScore: {
                single: void 0,
                multiple: void 0,
                judge: void 0,
                fillBlank: void 0,
                askAnswer: void 0,
            }, // 批量分数
        };
    },
    mounted() {
        this.getQUestions();
        if (this.$route.query.id) {
            this.getExamPaperInfo(this.$route.query.id);
        }
    },
    computed: {
        // 设置试卷总分
        paperScoreTotal() {
            let total = 0;
            for (let i in this.examPaper.content) {
                this.examPaper.content[i].forEach((item) => {
                    if (Number(item.score)) {
                        total += Number(item.score);
                    }
                });
            }
            if (total > 100) {
                this.$message.warning("试卷总分必须为100！");
            }
            return total;
        },
    },
    methods: {
        // 获取编辑数据
        getExamPaperInfo(paperId) {
            let params = {
                theory_test_paper_id: paperId,
            };
            theoryTestPaperSecondPage(params)
                .then((res) => {
                    let resData = res.data.theory_test_paper_data;
                    this.paperId = resData.theory_test_paper_id;
                    this.paperName = resData.name;
                    this.paperLevel = resData.level_id;
                    this.examPaper.classId = resData.category_id_arr;
                    // this.paperCategoryId = this.examPaper.classId[this.examPaper.classId.length - 1];
                    resData.test_paper_topic_data.forEach((item) => {
                        item.score = Number(item.score);
                        item.options = JSON.parse(item.options);
                        if (item.type_id === 1) {
                            this.examPaper.content.single.push(item);
                        } else if (item.type_id === 2) {
                            item.score = Number(item.score);
                            this.examPaper.content.multiple.push(item);
                        } else if (item.type_id === 3) {
                            this.examPaper.content.judge.push(item);
                        } else if (item.type_id === 4) {
                            this.examPaper.content.fillBlank.push(item);
                        } else if (item.type_id === 5) {
                            this.examPaper.content.askAnswer.push(item);
                        }
                    });
                    _.map(this.examPaper.content, (item) => {
                        _.map(item, (val) => {
                            this.questionIds.push(val.theory_topic_id);
                        });
                    });
                    this.examPaper.paper_number = this.questionIds.length;
                })
                .catch((err) => {
                    console.log("err", err);
                });
        },
        // 每种题型的总分
        getTypeScore(contentItem) {
            let totalScore = 0;
            contentItem.forEach((item) => {
                if (Number(item.score)) {
                    totalScore += item.score;
                }
            });
            return totalScore;
        },
        // 选择题型
        tabClick(index) {
            this.theQuestionType = index;
            this.getQUestions();
        },
        // 选择试题难度
        difficultyClick(index) {
            this.theDiffIndex = index;
            this.getQUestions();
        },
        // 选择试题分类
        filtrationClass(val) {
            if (val.length) {
                this.getQUestions();
            } else {
                this.filtrationValue = "";
                this.getQUestions();
            }
        },
        // 选择试卷分类
        filtrationPaperClass(val) {
            // if(val.length){
            //     this.paperCategoryId = val[val.length-1];
            // } else {
            //     this.paperCategoryId = '';
            // }
        },
        // 最新上传 使用最多
        tabSort(sort) {
            this.sortType = sort;
            this.getQUestions();
        },
        // 输入搜索试题
        searchQuestion() {
            this.getQUestions();
        },
        // 显示隐藏答案
        isShowAnswer(val) {
            this.getQUestions();
        },
        // 全部添加 全部移除
        addAllInPaper() {
            if (this.allIn) {
                // switch (this.theQuestionType) {
                //     case 1:
                //         this.delAllSameQuestion("single", this.questionList);
                //         break;
                //     case 2:
                //         this.delAllSameQuestion("multiple", this.questionList);
                //         break;
                //     case 3:
                //         this.delAllSameQuestion("judge", this.questionList);
                //         break;
                //     case 4:
                //         this.delAllSameQuestion("fillBlank", this.questionList);
                //         break;
                //     case 5:
                //         this.delAllSameQuestion("askAnswer", this.questionList);
                //         break;
                // }
                let arr=["single","multiple","judge","fillBlank","askAnswer"]
                for(let i=0;i<arr.length;i++){
                    this.delAllSameQuestion(arr[i], this.questionList);
                }
            } else {
                // switch (this.theQuestionType) {
                //     case 1:
                //         this.addAllSameQuestion("single", this.questionList);
                //         break;
                //     case 2:
                //         this.addAllSameQuestion("multiple", this.questionList);
                //         break;
                //     case 3:
                //         this.addAllSameQuestion("judge", this.questionList);
                //         break;
                //     case 4:
                //         this.addAllSameQuestion("fillBlank", this.questionList);
                //         break;
                //     case 5:
                //         this.addAllSameQuestion("askAnswer", this.questionList);
                //         break;
                // }
                let arr=["single","multiple","judge","fillBlank","askAnswer"]
                    for(let i=0;i<arr.length;i++){
                        this.addAllSameQuestion(arr[i], this.questionList);
                    }
            }
            this.allIn = !this.allIn;
        },
        addAllSameQuestion(questionType, questionList) {
            let arr=["single","multiple","judge","fillBlank","askAnswer"]
            //同类型题目全部加入试卷
            if (this.questionList.length) {
                _.map(questionList, (item) => {
                        if (this.questionIds.indexOf(item.theory_topic_id) === -1) {
                            this.questionIds.push(item.theory_topic_id);
                            this.examPaper.content[arr[item.type_id-1]].push(item);
                        }
                });
                this.examPaper.paper_number = this.questionIds.length;
            }
        },
        delAllSameQuestion(questionType, questionList) {
            //同类型题目全部从试卷移除
            this.questionList.forEach((item) => {
                this.questionIds.forEach((item2, index2) => {
                    if (item.theory_topic_id === item2) {
                        this.questionIds.splice(index2, 1);
                    }
                });
            });
            this.examPaper.content[questionType] = [];
            this.examPaper.paper_number = this.questionIds.length;
        },
        // 单个添加
        addQInPaper(data) {
            let isExist = this.questionIds.indexOf(data.theory_topic_id);
            if (isExist === -1) {
                let question = {};
                for (let i in data) {
                    question[i] = data[i];
                }
                this.questionIds.push(question.theory_topic_id);
                switch (question.type_id) {
                    case 1:
                        this.examPaper.content.single.push(question);
                        break;
                    case 2:
                        this.examPaper.content.multiple.push(question);
                        break;
                    case 3:
                        this.examPaper.content.judge.push(question);
                        break;
                    case 4:
                        this.examPaper.content.fillBlank.push(question);
                        break;
                    case 5:
                        this.examPaper.content.askAnswer.push(question);
                        break;
                }
            } else {
                this.removeQuestionByItem(data);
            }
            this.examPaper.paper_number = this.questionIds.length; //题目总数
        },
        // 第二步删除
        deleteOneQ(data) {
            this.removeQuestionByItem(data);
            if (this.questionIds.length === 0) {
                this.$message({
                    type: "warning",
                    message: "请重新选题，谢谢~",
                    duration: 1000,
                    onClose: () => {
                        this.step = 1;
                    },
                });
            }
        },
        // 单个删除
        removeQuestionByItem(item) {
            switch (Number(item.type_id)) {
                case 1:
                    this.removeQuestionById("single", item.theory_topic_id);
                    break;
                case 2:
                    this.removeQuestionById("multiple", item.theory_topic_id);
                    break;
                case 3:
                    this.removeQuestionById("judge", item.theory_topic_id);
                    break;
                case 4:
                    this.removeQuestionById("fillBlank", item.theory_topic_id);
                    break;
                case 5:
                    this.removeQuestionById("askAnswer", item.theory_topic_id);
                    break;
                case 6:
                    this.removeQuestionById("operation", item.theory_topic_id);
                    break;
            }
        },
        removeQuestionById(questionType, qId) {
            this.questionIds = this.questionIds.filter(function(elem) {
                return elem !== qId;
            });
            this.examPaper.content[questionType] = _.filter(this.examPaper.content[questionType], (item) => {
                return item.theory_topic_id !== qId;
            });
        },
        // 清空全部所选试题
        questionsClear() {
            this.examPaper = {
                name: "",
                content: {
                    single: [],
                    multiple: [],
                    judge: [],
                    fillBlank: [],
                    askAnswer: [],
                    operation: [],
                },
                scoreTotal: 0,
                paper_number: 0,
            };
            this.questionIds = [];
            this.allIn = false;
        },
        // 删除某种题型
        removeOneQ(data) {
            switch (data) {
                case "single":
                    this.removeTopic("single");
                    break;
                case "multiple":
                    this.removeTopic("multiple");
                    break;
                case "judge":
                    this.removeTopic("judge");
                    break;
                case "fillBlank":
                    this.removeTopic("fillBlank");
                    break;
                case "askAnswer":
                    this.removeTopic("askAnswer");
                    break;
                case "operation":
                    this.removeTopic("operation");
                    break;
            }
        },
        removeTopic(topicType) {
            let questiomTmp = this.examPaper.content[topicType];
            this.examPaper.content[topicType] = [];
            let qIdsTmp = _.map(questiomTmp, function(item) {
                return item.theory_topic_id;
            });
            this.questionIds = this.questionIds.filter(function(item) {
                return qIdsTmp.indexOf(item) === -1;
            });
            this.examPaper.paper_number = this.questionIds.length;
        },
        allowType(allow_type) {
            return [0, allow_type].indexOf(parseInt(this.theQuestionType)) !== -1
        },
        // 请求试题列表
        getQUestions() {
            let params = {};
            if (this.theQuestionType) {
                params.type_id = this.theQuestionType;
            }
            if (this.theDiffIndex) {
                params.difficulty_id = this.theDiffIndex;
            }
            if (this.filtrationValue) {
                params.category_id = this.filtrationValue[this.filtrationValue.length - 1]
            }
            if (this.sortType) {
                params.sort = this.sortType;
            }
            if (this.isShow) {
                params.is_show_answer = 1;
            }
            if (this.topicContent) {
                params.like = this.topicContent;
            }
            this.$refs['paperScroll'].wrap.scrollTop = 0
            this.singleItem=[]
            this.multipleItem=[]
            this.judgeItem=[]
            this.fillItem=[]
            this.questionItem=[]
            theoryTestPaperFirstPage(params)
                .then((res) => {
                    this.theQuestionDifficulty = res.data.question_difficulty; //题型难度列表
                    this.theQuestionTypeArr = res.data.question_type //题型类型列表
                    this.levelList = res.data.test_paper_level;
                    if (this.classes.length === 0) {
                        this.classes = this.formDataClasses(res.data.category_data);
                    }
                    if (res.data.theory_topic_list.length) {
                        let _this = this;
                        this.questionList = _.map(res.data.theory_topic_list, function(item) {
                            if (_this.isShow) {
                                item.answer = JSON.parse(item.answer);
                            }
                            item.options = JSON.parse(item.options);
                            item.score = void 0;
                            return item;
                        });
                        this.questionList.forEach((item,index)=>{
                            if(item.type_id==1){
                                let obj={
                                    analysis:item.analysis,
                                    difficulty_id:item.difficulty_id,
                                    answer: item.answer,
                                    options:item.options,
                                    score:item.score,
                                    theory_topic_id:item.theory_topic_id,
                                    title:item.title,
                                    type_id:item.type_id,
                                    use_num:item.use_num
                                }
                                this.singleItem.push(obj)
                            }
                            if(item.type_id==2){
                                let obj={
                                    analysis:item.analysis,
                                    difficulty_id:item.difficulty_id,
                                    answer: item.answer,
                                    options:item.options,
                                    score:item.score,
                                    theory_topic_id:item.theory_topic_id,
                                    title:item.title,
                                    type_id:item.type_id,
                                    use_num:item.use_num
                                }
                                this.multipleItem.push(obj)
                            }
                            if(item.type_id==3){
                                let obj={
                                    analysis:item.analysis,
                                    difficulty_id:item.difficulty_id,
                                    answer: item.answer,
                                    options:item.options,
                                    score:item.score,
                                    theory_topic_id:item.theory_topic_id,
                                    title:item.title,
                                    type_id:item.type_id,
                                    use_num:item.use_num
                                }
                                this.judgeItem.push(obj)
                            }
                            if(item.type_id==4){
                                let obj={
                                    analysis:item.analysis,
                                    difficulty_id:item.difficulty_id,
                                    answer: item.answer,
                                    options:item.options,
                                    score:item.score,
                                    theory_topic_id:item.theory_topic_id,
                                    title:item.title,
                                    type_id:item.type_id,
                                    use_num:item.use_num
                                }
                                this.fillItem.push(obj)
                            }
                            if(item.type_id==5){
                                let obj={
                                    analysis:item.analysis,
                                    difficulty_id:item.difficulty_id,
                                    answer: item.answer,
                                    options:item.options,
                                    score:item.score,
                                    theory_topic_id:item.theory_topic_id,
                                    title:item.title,
                                    type_id:item.type_id,
                                    use_num:item.use_num
                                }
                                this.questionItem.push(obj)
                            }
                        })
                        // console.log(this.singleItem)
                        if (this.questionList.length) {
                            let num = 0;
                            _.map(this.questionList, (item) => {
                                if (this.questionIds.indexOf(item.theory_topic_id) === -1) {
                                    num = 0;
                                } else {
                                    num = 1;
                                }
                            });
                            if (num === 0) {
                                this.allIn = false;
                            } else {
                                this.allIn = true;
                            }
                        }
                    } else {
                        this.questionList = [];
                    }
                    this.questionTotal = res.data.total_topic;
                })
                .catch((err) => {
                    console.log("err", err);
                });
        },
        formDataClasses(data) {
            data.forEach((item) => {
                if (item.children) {
                    if (item.children.length == 0) {
                        item.children = null;
                    } else {
                        this.formDataClasses(item.children);
                    }
                }
            });
            return data;
        },
        // 上一步
        changeStep() {
            if (this.step > 1) {
                this.step--;
            } else {
                this.$router.go(-1);
            }
        },
        // 下一步
        nextClick() {
            if (this.step === 1) {
                if (this.questionIds.length) {
                    this.step++;
                } else {
                    this.$message({
                        type: "error",
                        message: "请添加试题到试卷",
                        duration: 1000,
                    });
                }
            } else if (this.step === 2) {
                let isMsg = true;
                if (!this.paperName) {
                    this.$refs["set_paperName"].$el.classList.add("isError");
                    isMsg = false;
                } else {
                    this.$refs["set_paperName"].$el.classList.remove("isError");
                }
                if (!this.paperLevel) {
                    this.$refs["set_paperLevel"].$el.classList.add("isError");
                    isMsg = false;
                } else {
                    this.$refs["set_paperLevel"].$el.classList.remove("isError");
                }
                if (!this.paperCategoryId) {
                    // this.$refs['set_paperCategoryId'].$el.classList.add('isError');
                    // isMsg = false;
                } else {
                    // this.$refs['set_paperCategoryId'].$el.classList.remove('isError');
                }
                if (!isMsg) {
                    this.$message.warning("请设置试卷信息");
                    return;
                }
                for (let i in this.examPaper.content) {
                    if (this.examPaper.content[i].length) {
                        let length = this.examPaper.content[i].length;
                        for (let j = 0; j < length; j++) {
                            if (!this.examPaper.content[i][j].score) {
                                this.$message.warning("请为每道试题设置分数！");
                                return;
                            }
                        }
                    }
                }
                if (this.paperScoreTotal !== 100) {
                    this.$message.warning("试卷总分必须为100");
                    return;
                }
                this.step++;
            } else if (this.step === 3) {
                let topicData = [];
                let num = 1;
                for (let i in this.examPaper.content) {
                    this.examPaper.content[i].forEach((item) => {
                        let obj = {
                            theory_topic_id: item.theory_topic_id,
                            sort: num,
                            score: item.score,
                        };
                        topicData.push(obj);
                        num++;
                    });
                }
                let theory_topic_data = JSON.stringify(topicData);
                let formData = new FormData();
                formData.append("name", this.paperName);
                formData.append("level", this.paperLevel);
                // formData.append('category_id',this.paperCategoryId);
                formData.append("theory_topic_data", theory_topic_data);
                if (this.paperId) {
                    formData.append("theory_test_paper_id", this.paperId);
                }
                theoryTestPaperEdit(formData)
                    .then((res) => {
                        this.$message.success(res.msg);
                        this.step++;
                    })
                    .catch((err) => {
                        console.log("err", err);
                    });
            }
        },
        // 第四步返回列表
        toPaperList() {
            this.$router.push("/theory/questions");
        },
        // 设置批量分数
        setLotScore(key, val) {
            if (!val) return this.$message.error('请输入有效数值！');
            this.examPaper.content[key].forEach(item => item.score = val);
            setTimeout(() => {
                this.lotScore[key] = void 0;
            }, 500);
        }
    },
};
